import * as React from 'react';
import { useState } from 'react';
import styled from '@emotion/styled';
import { Link } from '../../components/wrapper-lang';
const StyledContent = styled.div `
  dl {
    display: flex;
    flex-wrap: wrap;
  }

  dt {
    flex: 0 1 auto;
    width: 25%;
  }

  dd {
    flex: 0 1 auto;
    width: 75%;
  }

  dt,
  dd {
    font-size: 1rem;
    padding: 0 0 1rem;

    p {
      margin-bottom: 0.5rem;
      line-height: 1.4;
    }
  }

  dt {
    opacity: 0.6;
  }

  a.tab-content-file {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    padding: 0.5rem 0.85rem;
    background: #1895dd;
    & > span {
      vertical-align: top;
    }
  }
`;
const TabContent = ({ selected, steps }) => {
    if (!selected)
        return null;
    const renderLink = (link) => {
        if (!link)
            return null;
        if (link.url.includes('http')) {
            return (<a className="tab-content-file" href={link.url}>
          {link.label}
        </a>);
        }
        else {
            return (<Link className="tab-content-file" to={link.url}>
          {link.label}
        </Link>);
        }
    };
    return (<StyledContent>
      <dl>
        {steps.map(({ label, content, file, link }) => (<React.Fragment key={label}>
            <dt>{label}</dt>
            <dd>
              <p>{content}</p>
              {file && (<a className="tab-content-file" href={file} title={`Download ${label} file`}>
                  다운로드
                </a>)}
              {renderLink(link)}
            </dd>
          </React.Fragment>))}
      </dl>
    </StyledContent>);
};
const Nav = styled.nav `
  ul {
    margin-bottom: 1rem;
  }

  li {
    display: inline-block;
    padding: 1rem 2rem 1rem 0;
    a {
      position: relative;
      text-decoration: none;
      color: inherit;
      opacity: 0.6;
      font-size: 1.25rem;
      line-height: 1.25;
      padding-bottom: 2px;
      transition: opacity 0.3s ease;
    }

    a:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -4px;
      width: 100%;
      height: 2px;
      opacity: 0;
      background: ${({ theme }) => theme.fg};
      transform-origin: center left;
      transform: scaleX(0.2);
      transition: all 0.3s ease;
    }

    a:hover {
      opacity: 1;
    }

    a.is-selected {
      opacity: 1;
    }

    a.is-selected:after {
      transform: scaleX(1);
      opacity: 0.4;
    }
  }
`;
const Tabs = ({ methods }) => {
    const [selectedId, setSelectedId] = useState(0);
    const makeId = i => `#method-${i + 1}`;
    const select = i => e => {
        e.preventDefault();
        setSelectedId(i);
    };
    return (<React.Fragment>
      <Nav>
        <ul>
          {methods.map(({ type }, i) => (<li key={makeId(i)}>
              <a className={selectedId === i ? 'is-selected' : ''} onClick={select(i)} href={makeId(i)} title={type}>
                {type}
              </a>
            </li>))}
        </ul>
      </Nav>
      <div>
        {methods.map(({ steps }, i) => (<TabContent steps={steps} key={makeId(i)} selected={selectedId === i}/>))}
      </div>
    </React.Fragment>);
};
const StyledSection = styled.section `
  header {
    font-size: 1.5rem;
    line-height: 1.25;
    margin-bottom: 1.5rem;
  }
`;
export const ApplicationMethod = ({ method }) => {
    if (!method)
        return null;
    const { title, methods } = method;
    return (<StyledSection>
      <header>{title}</header>
      <Tabs methods={methods}/>
    </StyledSection>);
};
