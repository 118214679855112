import * as React from 'react';
import styled from '@emotion/styled';
import { mqs } from '../../components/style-utils';
import { Icon } from '../../components/icons';
export const Flexer = styled.div `
  flex: 0 1 auto;

  ${mqs[0]} {
    width: 100%;
    margin-left: 0;
  }

  ${mqs[1]} {
    width: 100%;
    margin-left: 0;
  }

  ${mqs[2]} {
    width: 50%;
    margin-left: 25%;
  }

  ${mqs[3]} {
    width: 50%;
    margin-left: 25%;
  }

  ${mqs[4]} {
    width: 60%;
    margin-left: 20%;
  }
`;
const StyledSection = styled.section `
  position: relative;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.fg};
  color: ${({ theme }) => theme.bg};
  padding: 1.5rem;
  box-sizing: border-box;
  margin-bottom: 3.5rem;

  header {
    font-size: 1.5rem;
    line-height: 1.25;
    margin-bottom: 1rem;
  }

  hr {
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.bg};
    opacity: 0.2;
    margin: 0;
    padding: 0;
  }

  ul {
    display: flex;

    & > li {
      flex: 0 1 auto;
      padding: 1rem 1rem 1rem 0;
    }

    & > li > p {
      font-size: 1rem;
      line-height: 1.4;
      display: inline-block;
      padding-right: 1rem;
    }

    & > li > p:first-of-type {
      opacity: 0.6;
    }
  }

  .detail-procedure {
    width: 100%;
    text-align: center;

    p {
      font-size: 1rem;
      opacity: 0.6;
      padding: 1rem 0;
    }

    ol {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: top;

      li {
        flex: 0 1 25%;
        position: relative;
        text-align: center;
      }

      li:last-of-type {
        .detail-arrow {
          display: none;
        }
      }
    }
  }

  ${mqs[0]} {
    .detail-procedure ol li {
      flex: 0 1 50%;
      margin-bottom: 1rem;
    }

    .detail-procedure ol li:nth-of-type(2n) {
      .detail-arrow {
        display: none;
      }
    }
  }

  ${mqs[1]} {
    .detail-procedure ol li {
      flex: 0 1 50%;
      margin-bottom: 1rem;
    }

    .detail-procedure ol li:nth-of-type(2n) {
      .detail-arrow {
        display: none;
      }
    }
  }

  .detail-procedure-step {
    position: relative;
    margin: 0 auto;
    width: 3.5rem;
    height: 3.5rem;
    text-align: center;
    line-height: 3.5rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.bg};
    color: ${({ theme }) => theme.fg};
  }

  .detail-arrow {
    position: absolute;
    color: ${({ theme }) => theme.bg};
    top: 20%;
    transform: translateX(50%);
    right: 0;
    opacity: 0.4;
  }
`;
export const AuditionDetail = ({ detail }) => {
    if (!detail)
        return null;
    const { title, entries, procedure } = detail;
    return (<Flexer>
      <StyledSection>
        <header>{title}</header>
        <hr />
        <ul>
          {entries.map(({ label, content }) => (<li key={label}>
              <p>{label}</p>
              <p>{content}</p>
            </li>))}
        </ul>
        <hr />
        <div className="detail-procedure">
          <p>{procedure.label}</p>
          <ol>
            {procedure.content.map((item, i) => (<li key={i}>
                <div className="detail-procedure-step">{i + 1}</div>
                <div className="detail-arrow">
                  <Icon color="inherit" iconName="arrowNext"/>
                </div>
                <p>{item}</p>
              </li>))}
          </ol>
        </div>
      </StyledSection>
    </Flexer>);
};
