/* eslint-disable @typescript-eslint/camelcase */
import * as React from 'react';
import { graphql } from 'gatsby';
import pose from 'react-pose';
import SEO from '../../components/seo';
import { FlexContent } from '../../components/grid';
import { H1, Header, MarkdownStyler, Layout, SubTitle, } from '../../components/template.common';
import { ApplicationMethod } from './application-method';
import { AuditionDetail } from './audition-detail';
const TRANSITION = {
    ease: 'easeOut',
};
const PoseHeader = pose.div({
    enter: {
        delay: 150,
        opacity: 1,
        transition: TRANSITION,
    },
    exit: {
        opacity: 0,
        transition: TRANSITION,
    },
});
const PoseContent = pose.div({
    enter: {
        delay: 250,
        opacity: 1,
        y: 0,
        transition: TRANSITION,
    },
    exit: {
        opacity: 0,
        y: -5,
        transition: TRANSITION,
    },
});
const AuditionPage = ({ data, pageContext }) => {
    const { frontmatter, html } = data.audition;
    const { title, subTitle, method, detail, description } = frontmatter;
    const { lang } = pageContext;
    return (<Layout>
      <PoseHeader>
        <Header>
          <FlexContent>
            <SEO {...{
        title,
        lang,
        description,
    }}/>
            <H1 dangerouslySetInnerHTML={{
        __html: title,
    }}/>
            {subTitle && <SubTitle>{subTitle}</SubTitle>}
          </FlexContent>
        </Header>
      </PoseHeader>
      <PoseContent>
        <AuditionDetail detail={detail}/>
        <FlexContent>
          <ApplicationMethod method={method}/>
        </FlexContent>
        <article>
          <FlexContent>
            <MarkdownStyler lang={lang} dangerouslySetInnerHTML={{
        __html: html,
    }}/>
          </FlexContent>
        </article>
      </PoseContent>
    </Layout>);
};
export default AuditionPage;
export const query = graphql `
  query AuditionPage($lang: String!) {
    audition: markdownRemark(
      frontmatter: { type: { eq: "page/audition" }, lang: { eq: $lang } }
    ) {
      html
      frontmatter {
        title
        subTitle
        detail {
          title
          entries {
            label
            content
          }
          procedure {
            label
            content
          }
        }
        method {
          title
          methods {
            type
            steps {
              label
              content
              file
              link {
                url
                label
              }
            }
          }
        }
      }
    }
  }
`;
